<template>
    <v-app>
        <v-app-bar app color="success" dark>
            <div class="d-flex align-center">
                <v-btn
                    style="color: #4caf50; background-color: #fff"
                    class="ma-0 ml-2 px-3"
                    @click="goBackToDistributions"
                    v-if="showBackToDistributions()"
                >
                    <v-icon class="mr-1">mdi-arrow-left</v-icon>
                    Back To Distributions</v-btn
                >
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-btn
                    style="color: #4caf50; background-color: #fff"
                    class="ma-0 ml-2 px-3"
                    @click="goToLogin"
                    v-if="!isLoginPage()"
                    ><v-icon class="mr-1">mdi-logout</v-icon>Logout</v-btn
                >
            </div>
        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
    methods: {
        isLoginPage() {
            return this.$route.path == "/login";
        },
        showBackToDistributions() {
            return this.$route.path.includes("distribution-items");
        },
        goToLogin() {
            this.$router.push({
                name: "Login",
            });
        },
        goBackToDistributions() {
            this.$router.push({
                name: "SelectDistributions",
            });
        },
    },
};
</script>
