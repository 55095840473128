/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
        path: "/login/:path?",
        name: "Login",
        view: "Login",
    },
    {
        path: "/select-distributions",
        name: "SelectDistributions",
        view: "SelectDistributions",
    },
    {
        path: "/distribution-items/:id",
        name: "DistributionItems",
        view: "DistributionItems",
    },
];
