import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "@/store";
import axios from "axios";
import { sync } from "vuex-router-sync";
import router from "@/router";

sync(store, router);

Vue.config.productionTip = false;

const instance = axios.create({
    baseURL: window.location.origin.startsWith("http://localhost")
        ? "https://localhost:44306"
        : window.location.origin,
});

function errorHandler(error) {
    const text =
        error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "An error has occurred";

    throw new Error(text);
}

instance.interceptors.response.use((response) => {
    return response ? response.data : response;
}, errorHandler);

Vue.prototype.$http = instance;

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
