// https://vuex.vuejs.org/en/actions.html

export default {
    AUTH_REQUEST({ commit }, data) {
        return new Promise((resolve) => {
            const token = new Date();

            commit("AUTH_REQUEST");

            localStorage.setItem("name", data.account.nameY);
            localStorage.setItem("familyID", data.account.id);
            localStorage.setItem("site", data.account.site);
            localStorage.setItem("user-token", token);

            commit("AUTH_SUCCESS", {
                token: token,
                account: data.account,
            });

            resolve(true);
        });
    },
    AUTH_LOGOUT({ commit }) {
        return new Promise((resolve) => {
            localStorage.removeItem("name");
            localStorage.removeItem("familyID");
            localStorage.removeItem("site");
            localStorage.removeItem("user-token");

            commit("AUTH_LOGOUT");

            resolve();
        });
    },
};
